import { css } from '@emotion/react'
import { Dayjs } from 'dayjs'
import React, { memo, useMemo } from 'react'
import { Line, LineChart, ReferenceLine, YAxis } from 'recharts-new'
import { LiteralUnion } from 'type-fest'
import { globalGreen, globalGrey, globalRed } from '~/modules/AppLayout/Colors'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { Socket } from '~/modules/SDK/socket2/Socket'
import dayAPI from '~/utils/dayAPI'

/**
 * Symbol歷史小線圖
 *
 * @example
 *   ;<SymbolTrendChart symbol={props.symbol} />
 *
 * @example <caption>最近天數</caption>
 *   ;<SymbolTrendChart symbol={props.symbol} recentDays={30} />
 */
const SymbolTrendChart = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    width?: number
    height?: number
    /** E.g. `30` 即為 30 天 */
    recentDays?: number
    /** 實際要採用的數量 */
    count?: number
    /** 預設 `'1D'` */
    resolution?: LiteralUnion<'1D' | '1H', string>
    showReferenceLine?: boolean
  }>
>(function SymbolTrendChart(props) {
  const recentDays = props.recentDays || 365
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const range: [number, number] = [
    endOnSpecDate
      .startOf('day')
      .add(-recentDays * 1.5, 'day')
      .unix(),
    endOnSpecDate.endOf('day').unix(),
  ]
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: range,
    resolution: props.resolution ?? '1D',
  })

  const data = useMemo(() => {
    return rawData
      ?.map(datum => ({
        close: datum.close,
      }))
      .slice(props.count ? Math.max((rawData?.length ?? 0) - props.count - 1, 0) : 0)
  }, [rawData, props.count])

  if (!data || (data && data.length === 0))
    return (
      <div
        css={css`
          width: ${props.width};
          height: ${props.height};
        `}
      >
        ...尚未載入
      </div>
    )
  const isGrowing = data[data.length - 1].close - data[0].close > 0
  const yRange: [number, number] = [
    Math.min(...(data?.map(datum => datum.close) || [])),
    Math.max(...(data?.map(datum => datum.close) || [])),
  ]
  return (
    <LineChart
      width={props.width || 120}
      height={props.height || 48}
      data={data}
    >
      {props.showReferenceLine && (
        <ReferenceLine
          y={data[0].close}
          stroke={globalGrey.g300}
        />
      )}
      <YAxis
        domain={yRange}
        hide={true}
      />
      <Line
        type='basis'
        dot={false}
        dataKey='close'
        stroke={isGrowing ? globalRed.r700 : globalGreen.gA700}
      />
    </LineChart>
  )
})

export default SymbolTrendChart
