import React, { memo, useMemo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import dayAPI from '~/utils/dayAPI'
import { css } from '@emotion/react'
import { Dayjs } from 'dayjs'
import { globalBlueGrey, globalGreen, globalRed } from '~/modules/AppLayout/Colors'
import { ADJUST_RECENT_DAY_FACTOR } from '~/modules/screener/constants'
import { fontWeight500 } from '~/css/font'

const SymbolPrice = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    recentDays?: number
  }>
>(function SymbolPrice(props) {
  const recentDays = props.recentDays || 365
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const data = useMemo(
    () =>
      rawData?.map(datum => ({
        close: datum.close,
      })),
    [rawData],
  )

  if (!data || (data && data.length === 0)) return <div>...尚未載入</div>

  const latestCloses = [data[data.length - 2].close, data[data.length - 1].close]
  const diff = latestCloses[1] - latestCloses[0]
  return (
    <p
      css={css`
        ${fontWeight500};
        font-size: 0.8rem;
        color: ${diff === 0 ? globalBlueGrey.bg800 : diff > 0 ? globalRed.r400 : globalGreen.g400};
      `}
    >
      {latestCloses[1]}
    </p>
  )
})

export default SymbolPrice
