import React, { memo, useMemo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import dayAPI from '~/utils/dayAPI'
import { toPercentage } from '~/utils/toPercentage'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { globalRed, globalGreen, globalBlueGrey } from '~/modules/AppLayout/Colors'
import { Dayjs } from 'dayjs'
import first from 'lodash/first'
import last from 'lodash/last'
import { ADJUST_RECENT_DAY_FACTOR } from '~/modules/screener/constants'
import { fontWeight500 } from '~/css/font'

const textCss = css`
  width: 100%;
  border-radius: 4px;
  padding: 2px;
  ${flex.h.allCenter};
  & > * {
    margin: 0 4px;
    ${fontWeight500};
    font-size: 0.8rem;
  }
`
/** 時間內的變化 一日內：兩根K棒 五日內：五根K棒 */
const Day = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    recentDays?: number
    /** 實際的K棒數量 */
    count: number
  }>
>(function Day(props) {
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const recentDays = props.recentDays || 365
  const count = props.count
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const sliceCount = Math.max((rawData?.length ?? 0) - count - 1, 0)

  const data = useMemo(
    () =>
      rawData
        ?.map(datum => ({
          close: datum.close,
        }))
        .slice(sliceCount),
    [rawData, sliceCount],
  )

  if (!data || (data && data.length === 0)) return <div>...尚未載入</div>

  const change = toPercentage(last(data)?.close ?? 0, first(data)?.close ?? 0, true)

  return (
    <div
      css={css`
        background: ${change === 0
          ? globalBlueGrey.bg100
          : change > 0
          ? globalRed.r100
          : globalGreen.g100};
        ${textCss}
      `}
    >
      <p>{change}</p>
    </div>
  )
})

/** X時間內變化(%)，扣除加權指數的x時間內變化(%) */
const DayWIndex = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    recentDays?: number
    count: number
    index: number[]
  }>
>(function DayWIndex(props) {
  const recentDays = props.recentDays || 365
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const count = props.count
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const sliceCount = Math.max((rawData?.length ?? 0) - count - 1, 0)
  const data = useMemo(
    () => rawData?.map(datum => datum.close).slice(sliceCount),
    [rawData, sliceCount],
  )

  const indexData = props.index.slice(Math.max((props.index?.length ?? 0) - count - 1, 0))

  if (!data || (data && data.length === 0) || props.index.length < 2) return <div>...尚未載入</div>

  const change = toPercentage(last(data) ?? 0, first(data) ?? 0, true)

  const indexChange = toPercentage(last(indexData) ?? 0, first(indexData) ?? 0, true)

  return (
    <div
      css={css`
        background: ${change - indexChange === 0
          ? globalBlueGrey.bg100
          : change - indexChange > 0
          ? globalRed.r100
          : globalGreen.g100};
        ${textCss}
      `}
    >
      <p>{(change - indexChange).toFixed(2)}</p>
    </div>
  )
})

export default {
  Day,
  DayWIndex,
}
