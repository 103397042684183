import React, { memo, useMemo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import dayAPI from '~/utils/dayAPI'
import { toPercentage } from '~/utils/toPercentage'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { globalRed, globalGreen } from '~/modules/AppLayout/Colors'
import { Dayjs } from 'dayjs'
import last from 'lodash/last'
import mean from 'lodash/mean'
import { ADJUST_RECENT_DAY_FACTOR } from '~/modules/screener/constants'
import { fontWeight500 } from '~/css/font'

const SHORT_MA_DAY = 20
/**
 * 短乖離
 *
 * （最新收盤 - 20日ma）/ 20日ma
 */
const CSValue = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    recentDays?: number
  }>
>(function CSValue(props) {
  const recentDays = props.recentDays || 365
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const data = useMemo(
    () =>
      rawData
        ?.map(datum => ({
          close: datum.close,
        }))
        .slice(Math.max(rawData.length - recentDays - 1, 0)),
    [rawData, recentDays],
  )

  if (!data || (data && data.length === 0)) return <div>...尚未載入</div>
  const sliceCount = Math.max((rawData?.length ?? 0) - SHORT_MA_DAY - 1, 0)
  const recentData = data.slice(sliceCount).map(d => d.close)
  const ma = mean(recentData)

  const diff = toPercentage(last(data)?.close ?? 0, ma, true)

  return (
    <div
      css={css`
        width: 100%;
        border-radius: 4px;
        padding: 2px;
        ${flex.h.allCenter};
        background: ${diff > 0 ? globalRed.r100 : globalGreen.g100};
        & > * {
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        }
      `}
    >
      <p>{diff}</p>
    </div>
  )
})

export default CSValue
