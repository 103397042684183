import React, { memo, useMemo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import dayAPI from '~/utils/dayAPI'
import { toPercentage } from '~/utils/toPercentage'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { globalGreen, globalRed } from '~/modules/AppLayout/Colors'
import { Dayjs } from 'dayjs'
import mean from 'lodash/mean'
import { ADJUST_RECENT_DAY_FACTOR, LONG_MA_DAY, SHORT_MA_DAY } from '~/modules/screener/constants'
import { useSymbolHistoryOnceResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { fontWeight500 } from '~/css/font'

/**
 * 長乖離
 *
 * (20日ma - 60日ma) / 60日ma
 */
const SMValue = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Socket.SymbolString
    shortDay?: number
    longDay?: number
  }>
>(function CSValue(props) {
  const recentDays = props.longDay || 365
  const endOnSpecDate = props.endOnSpecDate || dayAPI()
  const { data: rawData } = useSymbolHistoryOnceResource({
    symbol: props.symbol,
    fromTo: [
      endOnSpecDate
        .startOf('day')
        .add(-recentDays * ADJUST_RECENT_DAY_FACTOR, 'day')
        .unix(),
      endOnSpecDate.endOf('day').unix(),
    ],
  })

  const data = useMemo(
    () =>
      rawData?.map(datum => ({
        close: datum.close,
      })),
    [rawData],
  )

  if (!data || (data && data.length === 0)) return <div>...尚未載入</div>
  const sliceLongDayCount = Math.max((rawData?.length ?? 0) - LONG_MA_DAY - 1, 0)
  const sliceShortDayCount = Math.max((rawData?.length ?? 0) - SHORT_MA_DAY - 1, 0)

  const longMa = mean(data.slice(sliceLongDayCount).map(d => d.close))
  const shortMa = mean(data.slice(sliceShortDayCount).map(d => d.close))

  const diff = toPercentage(shortMa, longMa, true)

  return (
    <div
      css={css`
        width: 100%;
        border-radius: 4px;
        padding: 2px;
        ${flex.h.allCenter};
        background: ${diff > 0 ? globalRed.r100 : globalGreen.g100};
        & > * {
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        }
      `}
    >
      <p>{diff}</p>
    </div>
  )
})

export default SMValue
