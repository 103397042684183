import React, { memo } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import {
  FormControl,
  InputLabel,
  InputProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import {
  ActionEnum,
  InstitutionEnum,
  PercentRangeStatusEnum,
  percentRangeStatusSeparator,
  PowerEnum,
  WarrantTypeEnum,
} from '~/modules/screener/containers/useStockScreenerResource'

export const QueryDatePicker = memo<
  ReactProps<{
    value: string
    changeDateDelegate: (value: Dayjs | null) => void
    label?: string
    inputProps?: Partial<InputProps>
    size?: 'medium' | 'small'
  }>
>(function QueryDatePicker(props) {
  return (
    <DatePicker
      inputFormat='YYYY/MM/DD'
      mask='____/__/__'
      renderInput={params => (
        <TextField
          size={props.size}
          {...params}
        />
      )}
      label={props.label}
      value={props.value}
      InputProps={props.inputProps}
      onChange={props.changeDateDelegate}
    />
  )
})

export const StrongWeakToggle = memo<
  ReactProps<{
    value: string
    clickDelegate: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
  }>
>(function StrongWeakToggle(props) {
  return (
    <ToggleButtonGroup size='small'>
      {Object.entries(PowerEnum).map(power => (
        <ToggleButton
          key={power[0]}
          value={power[0]}
          onClick={props.clickDelegate}
          selected={props.value === power[0]}
        >
          {power[1]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
})

export const TurnoverSlider = memo<
  ReactProps<{
    value: number
    changeDelegate: (event: Event, value: number | number[], activeThumb: number) => void
    width?: number
  }>
>(function TurnoverSlider(props) {
  return (
    <div
      css={css`
        ${flex.v.default};
        width: ${props.width ?? 100}px;
        & > p {
          font-size: 0.7rem;
          color: rgba(0, 0, 0, 0.54);
          margin: 0;
        }
      `}
    >
      <p>週轉率</p>
      <Slider
        step={0.05}
        value={props.value}
        min={0.05}
        max={0.5}
        marks
        valueLabelDisplay='on'
        onChange={props.changeDelegate}
      />
    </div>
  )
})

export const DaysSelect = memo<
  ReactProps<{
    value: number
    options: number[]
    changeDelegate: (event: SelectChangeEvent<number>, child: React.ReactNode) => void
    containerCss?: SerializedStyles
  }>
>(function DaysSelect(props) {
  return (
    <FormControl css={props.containerCss}>
      <InputLabel>採計天數</InputLabel>
      <Select
        css={css`
          width: 100%;
        `}
        size='small'
        label='採計天數'
        value={props.value}
        onChange={props.changeDelegate}
      >
        {props.options.map((day, index) => (
          <MenuItem
            key={index}
            value={day}
          >
            <div>{day}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export const SelectAmountRange = memo<
  ReactProps<{
    value: number
    options: { key: string; value: [number, number] }[]
    changeDelegate: (event: SelectChangeEvent<number>, child: React.ReactNode) => void
    containerCss?: SerializedStyles
  }>
>(function SelectAmountRange(props) {
  return (
    <FormControl css={props.containerCss}>
      <InputLabel disableAnimation={true}>成交值</InputLabel>
      <Select
        css={css`
          width: 100%;
        `}
        label='成交值'
        size='small'
        sx={{ width: '140px' }}
        value={props.value}
        onChange={props.changeDelegate}
      >
        {props.options.map((kv, index) => {
          return (
            <MenuItem
              key={index}
              value={index}
            >
              <div>{kv.key}</div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
})

export const BigThreeSelect = memo<
  ReactProps<{
    value: string
    changeDelegate: (
      event: SelectChangeEvent<keyof typeof InstitutionEnum>,
      child: React.ReactNode,
    ) => void
    containerCss?: SerializedStyles
  }>
>(function BigThreeSelect(props) {
  return (
    <FormControl css={props.containerCss}>
      <InputLabel>三大法人</InputLabel>
      <Select
        css={css`
          width: 100%;
        `}
        label='三大法人'
        size='small'
        value={props.value as keyof typeof InstitutionEnum}
        onChange={props.changeDelegate}
      >
        {Object.entries(InstitutionEnum).map((institution, index) => (
          <MenuItem
            key={index}
            value={institution[0]}
          >
            <div>{institution[1]}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export const PercentRangeStatusSelect = memo<
  ReactProps<{
    value: string
    changeDelegate: (event: SelectChangeEvent<keyof typeof PercentRangeStatusEnum>) => void
    containerCss?: SerializedStyles
  }>
>(function PercentRangeStatusSelect(props) {
  return (
    <FormControl css={props.containerCss}>
      <InputLabel>類型</InputLabel>
      <Select
        css={css`
          width: 100%;
        `}
        label='類型'
        size='small'
        value={props.value as keyof typeof PercentRangeStatusEnum}
        onChange={props.changeDelegate}
      >
        {Object.entries(PercentRangeStatusEnum).map((status, index) => {
          const wording = status[1].split(percentRangeStatusSeparator)
          return (
            <MenuItem
              key={index}
              value={status[0]}
            >
              <Tooltip
                placement='right'
                title={wording[1]}
              >
                <div>{wording[0]}</div>
              </Tooltip>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
})

export const BuySellToggle = memo<
  ReactProps<{
    value: string
    clickDelegate: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
  }>
>(function BuySellToggle(props) {
  return (
    <ToggleButtonGroup size='small'>
      {Object.entries(ActionEnum).map((action, index) => (
        <ToggleButton
          key={index}
          value={action[0]}
          onClick={props.clickDelegate}
          selected={props.value === action[0]}
        >
          {action[1]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
})

export const WarrantCallPutToggle = memo<
  ReactProps<{
    value: string
    clickDelegate: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
  }>
>(function WarrantCallPutToggle(props) {
  return (
    <ToggleButtonGroup size='small'>
      {Object.entries(WarrantTypeEnum).map((type, index) => (
        <ToggleButton
          key={index}
          value={type[0]}
          onClick={props.clickDelegate}
          selected={props.value === type[0]}
        >
          {type[1]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
})
