import { css } from '@emotion/react'
import { GridColDef, GridCellParams } from '@mui/x-data-grid'
import dayjs, { Dayjs } from 'dayjs'
import React, { memo } from 'react'
import { globalRed, globalGreen, globalYellow } from '~/modules/AppLayout/Colors'
import { flex, jc, oneGridArea } from '~/modules/AppLayout/FlexGridCss'
import SymbolTrendChart from '~/modules/SDK/Symbol/SymbolTrendChart'
import { toCommaSeparatedNumber } from '~/utils/representChineseNumberFormat'
import { AiFillStar } from 'react-icons/ai'
import { StockFutures } from '~/modules/SDK/Symbol/useCommonSymbolListStore'
import { fontWeight500 } from '~/css/font'
import CSValue from '~/modules/screener/Tags/CSValue'
import PricePercentageChange from '~/modules/screener/Tags/PricePercentageChange'
import SMValue from '~/modules/screener/Tags/SMValue'
import SymbolPrice from '~/modules/screener/SymbolPrice'
import { AvailableResourceEndpoint } from '~/modules/screener/containers/useConditionState'

const Star = memo(function Star() {
  return (
    <AiFillStar
      css={css`
        fill: ${globalYellow.y900};
        font-size: 0.8rem;
        height: 100%;
        margin: 0;
      `}
    />
  )
})

const rootGridTemplateName = 'root'
//name: 台積電
const nameColumn: (
  isPhone: boolean,
  dictionary: { [x: string]: string },
  futuresDictionary: StockFutures,
) => GridColDef = (
  isPhone: boolean,
  dictionary: { [x: string]: string },
  futuresDictionary: StockFutures,
) => ({
  field: '股票',
  headerName: '股票',
  headerAlign: 'center',
  sortable: false,
  width: isPhone ? 130 : 150,
  renderCell: function render(params: GridCellParams) {
    const symbol = params.row.symbol
    return (
      <div
        css={css`
          ${oneGridArea.parentGrid(rootGridTemplateName)}
          width: 100%;
          & > * {
            ${oneGridArea.childGridItem(rootGridTemplateName)}
          }
        `}
      >
        <div
          css={css`
            border-radius: 0 10px 10px 0;
            width: ${Math.abs(params.row.strength)}%;
            background: ${params.row.strength > 0 ? globalRed.r100 : globalGreen.g100};
          `}
        />
        <div
          css={css`
            ${flex.h.crossCenter};
            font-size: ${isPhone ? 0.8 : 1}rem;
            * {
              margin: 4px;
            }
          `}
        >
          <p>{symbol}</p>
          <p
            css={css`
              font-size: ${dictionary[symbol] ? (dictionary[symbol].length > 4 ? 0.8 : 1) : 0}rem;
            `}
          >
            {dictionary[symbol]}
          </p>
          {futuresDictionary[symbol] && <Star />}
        </div>
      </div>
    )
  },
})

const nameColumnNoColor: (
  isPhone: boolean,
  dictionary: { [x: string]: string },
  futuresDictionary: StockFutures,
) => GridColDef = (
  isPhone: boolean,
  dictionary: { [x: string]: string },
  futuresDictionary: StockFutures,
) => ({
  field: '股票',
  headerName: '股票',
  headerAlign: 'center',
  sortable: false,
  width: isPhone ? 100 : 150,
  renderCell: function render(params: GridCellParams) {
    const symbol = params.row.symbol
    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            ${flex.h.default}
            ${jc.flexStart};
            font-size: ${isPhone ? 0.8 : 1}rem;
            * {
              margin: 4px;
            }
          `}
        >
          <p>{symbol}</p>
          <p
            css={css`
              font-size: ${dictionary[symbol] ? (dictionary[symbol].length > 4 ? 0.8 : 1) : 0}rem;
            `}
          >
            {dictionary[symbol]}
          </p>
          <div
            css={css`
              ${flex.h.allCenter};
            `}
          >
            {futuresDictionary[symbol] && <Star />}
          </div>
        </div>
      </div>
    )
  },
})

// trend
// linechart
const trendColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: 'trend',
  headerName: '走勢',
  headerAlign: 'center',
  sortable: false,
  width: 84,
  renderCell: function render(params: GridCellParams) {
    return (
      <SymbolTrendChart
        symbol={params.row.symbol}
        width={80}
        height={36}
        endOnSpecDate={baseOn}
        count={90}
      />
    )
  },
})

// price
const priceColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: 'P',
  headerName: '成交價',
  sortable: false,
  width: 60,
  align: 'center',
  headerAlign: 'center',

  renderCell: function render(params: GridCellParams) {
    return (
      <SymbolPrice
        symbol={params.row.symbol}
        endOnSpecDate={baseOn}
      />
    )
  },
})

// 不傳入days是為了避免call兩次差不多的資料，而useSWR會cache，所以大家的預設區間都是60天
// count用來代表實際區間
const oneDayChangeColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: '1D',
  headerName: '日漲幅(%)',
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  width: 84,
  renderCell: function render(params: GridCellParams) {
    return (
      <PricePercentageChange.Day
        count={1}
        symbol={params.row.symbol}
        endOnSpecDate={baseOn}
      />
    )
  },
})

// 不傳入days是為了避免call兩次差不多的資料，而useSWR會cache，所以大家的預設區間都是60天
// count用來代表實際區間
const fiveDayChangeColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: '5D',
  headerName: '周漲幅(%)',
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  width: 84,
  renderCell: function render(params: GridCellParams) {
    return (
      <PricePercentageChange.Day
        count={5}
        symbol={params.row.symbol}
        endOnSpecDate={baseOn}
      />
    )
  },
})

// Zscore 預設欄位：CS, SM
const fiveDayCSColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: 'C/S',
  headerName: '短乖離',
  sortable: false,
  width: 72,
  renderCell: function render(params: GridCellParams) {
    return (
      <CSValue
        symbol={params.row.symbol}
        endOnSpecDate={baseOn}
      />
    )
  },
})

const fiveTwentySMColumn: (baseOn: Dayjs) => GridColDef = (baseOn: Dayjs) => ({
  field: 'S/M',
  headerName: '長乖離',
  sortable: false,
  width: 72,
  renderCell: function render(params: GridCellParams) {
    return (
      <SMValue
        symbol={params.row.symbol}
        endOnSpecDate={baseOn}
      />
    )
  },
})

const bigThreeStrength: GridColDef = {
  field: 'strength',
  headerName: '買賣超(張)',
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  width: 85,

  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {toCommaSeparatedNumber(params.row.diff_volume)}
      </p>
    )
  },
}

const bigThreeStrengthPercentage: GridColDef = {
  field: 'strength_percentage',
  headerName: '買賣超佔比(%)',
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  width: 105,

  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {params.row.diff_volume_percentage}
      </p>
    )
  },
}

const turnoverRate: GridColDef = {
  field: 'turnoverRate',
  headerName: '週轉率',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 80,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {params.value ?? '-'}
      </p>
    )
  },
}

const turnoverRateMa: GridColDef = {
  field: 'avgTurnoverRate',
  headerName: '平均週轉率',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 90,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {params.value ?? '-'}
      </p>
    )
  },
}

const extremeVolume: GridColDef = {
  field: 'volume',
  headerName: '成交量',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 90,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {isNaN(params.value as number) ? '-' : toCommaSeparatedNumber(params.value)}
      </p>
    )
  },
}

const extremeVolumeMa: GridColDef = {
  field: 'volumeMa',
  headerName: '平均成交量',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 100,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {isNaN(params.value as number) ? '-' : toCommaSeparatedNumber(params.value)}
      </p>
    )
  },
}

const warrantBsField: GridColDef = {
  field: 'diffQty',
  headerName: '買賣超張數',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 90,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {isNaN(params.value as number) ? '-' : toCommaSeparatedNumber(params.value)}
      </p>
    )
  },
}

const warrantBsValueField: GridColDef = {
  field: 'diffValue',
  headerName: '買賣超金額',
  align: 'center',
  headerAlign: 'center',
  sortable: true,
  width: 90,
  renderCell: function render(params: GridCellParams) {
    return (
      <p
        css={css`
          margin: 0 4px;
          ${fontWeight500};
          font-size: 0.8rem;
        `}
      >
        {isNaN(params.value as number) ? '-' : toCommaSeparatedNumber(params.value)}
      </p>
    )
  },
}

const generateColumns: (
  key: AvailableResourceEndpoint,
  isPhone: boolean,
  dictionary: {
    [x: string]: string
  },
  futuresDictionary: StockFutures,
  date: string,
) => GridColDef[] = (
  key: AvailableResourceEndpoint,
  isPhone: boolean,
  dictionary: {
    [x: string]: string
  },
  futuresDictionary: StockFutures,
  date: string,
) => {
  const currentResourceKey = key

  // basic columns
  const common = [
    nameColumn(isPhone, dictionary, futuresDictionary),
    trendColumn(dayjs(date)),
    priceColumn(dayjs(date)),
    oneDayChangeColumn(dayjs(date)),
    fiveDayChangeColumn(dayjs(date)),
  ]

  if (currentResourceKey === 'big3_consecutive') {
    common.splice(2, 0, bigThreeStrengthPercentage, bigThreeStrength)
  } else if (currentResourceKey === 'turnover_rate') {
    common.splice(2, 0, turnoverRate, turnoverRateMa)
  } else if (currentResourceKey === 'extreme_volume') {
    common.splice(2, 0, extremeVolume, extremeVolumeMa)
  } else if (currentResourceKey === 'warrant_chip') {
    common.splice(2, 0, warrantBsField, warrantBsValueField)
  }
  return [...common]
}

const defaultColumns: (
  isPhone: boolean,
  dictionary: {
    [x: string]: string
  },
  futuresDictionary: StockFutures,
  date: string,
) => GridColDef[] = (
  isPhone: boolean,
  dictionary: {
    [x: string]: string
  },
  futuresDictionary: StockFutures,
  date: string,
) => {
  // basic columns
  const common = [
    nameColumnNoColor(isPhone, dictionary, futuresDictionary),
    priceColumn(dayjs(date)),
    trendColumn(dayjs(date)),
  ]

  return [...common]
}

export { generateColumns, defaultColumns }
