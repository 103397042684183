import { useCallback, useState } from 'react'
import { proxy } from 'valtio'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'

const MAX_VOLUME = 10000000
const MAX_AMOUNT = 100000000000

const VOLUME_RANGE_ITEMS: { key: string; value: [number, number] }[] = [
  { key: '不限', value: [0, MAX_VOLUME] },
  { key: '1萬張以下', value: [0, 10000] },
  { key: '1萬張以上', value: [10000, MAX_VOLUME] },
  { key: '1萬 - 5萬', value: [10000, 50000] },
  { key: '5萬 - 10萬', value: [50000, 100000] },
  { key: '10萬 - 50萬', value: [100000, 500000] },
  { key: '50萬 - 100萬', value: [500000, 1000000] },
  { key: '100萬以上', value: [1000000, MAX_VOLUME] },
]

const VOLUME_SLIDER_ITEMS: { label: string; value: number }[] = [
  { label: '1', value: 1 },
  { label: '100', value: 100 },
  { label: '', value: 500 },
  { label: '1000', value: 1000 },
  { label: '', value: 5000 },
  { label: '1萬', value: 10000 },
  { label: '', value: 50000 },
  { label: '10萬', value: 100000 },
  { label: '', value: 500000 },
  { label: '100萬', value: 1000000 },
  { label: '1000萬', value: 10000000 },
]

export const TRADE_RANGE_ITEMS: { key: string; value: [number, number] }[] = [
  { key: '不限', value: [0, MAX_AMOUNT] },
  { key: '1億以下', value: [0, 100000000] },
  { key: '1億以上', value: [100000000, MAX_AMOUNT] },
  { key: '1億 - 5億', value: [100000000, 500000000] },
  { key: '5億 - 10億', value: [500000000, 1000000000] },
  { key: '10億以上', value: [1000000000, MAX_AMOUNT] },
  { key: '10億 - 50億', value: [1000000000, 5000000000] },
  { key: '50億 - 100億', value: [5000000000, 10000000000] },
  { key: '100億以上', value: [10000000000, MAX_AMOUNT] },
]

const AMOUNT_SLIDER_ITEMS: { label: string; value: number }[] = [
  { label: '1', value: 1 },
  { label: '100萬', value: 1000000 },
  { label: '1000萬', value: 10000000 },
  { label: '', value: 50000000 },
  { label: '1億', value: 100000000 },
  { label: '', value: 500000000 },
  { label: '10億', value: 1000000000 },
  { label: '', value: 5000000000 },
  { label: '100億', value: 10000000000 },
  { label: '', value: 50000000000 },
  { label: '1000億', value: 100000000000 },
]

export const useVolumeAmountStore = proxy({
  volumeGreaterThan: 1000 as number,
  volumeLessThan: MAX_VOLUME as number,
  amountGreaterThan: 1000000000 as number,
  amountLessThan: MAX_AMOUNT as number,
  doubleTradeValueIndex: 5 as number,
  doubleVolumeValueIndex: 0 as number,
  MAX_VOLUME,
  MAX_AMOUNT,
  VOLUME_SLIDER_ITEMS,
  VOLUME_RANGE_ITEMS,
  AMOUNT_SLIDER_ITEMS,
  TRADE_RANGE_ITEMS,
})
